import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import '../controllers'

if (!window._rails_loaded) {
  Rails.start()
}

Turbo.session.drive = false

import "@/javascript/components/polyfills"

import "lazysizes/plugins/unveilhooks/ls.unveilhooks"
import "lazysizes/plugins/include/ls.include"
import "lazysizes/lazysizes"
import "@/javascript/components/tools/lazy_sizes/data_module"
import "@/javascript/components/tools/announced_links"
import "@/javascript/components/tools/one_click"

import "@/javascript/components/ajax_forms"
import "@/javascript/components/callback_form"
import "@/javascript/components/cookie_banner"
import "@/javascript/components/equalizer"
import "@/javascript/components/external_scripts_loader"
import "@/javascript/components/faqs"
import "@/javascript/components/hyerhub_banner"
import "@/javascript/components/late_prefetch"
import "@/javascript/components/modal"
import "@/javascript/components/selects"
import "@/javascript/components/scrollto_onload"
import "@/javascript/components/toggling"

import "@/javascript/components/navigation"
import "@/javascript/components/load"

import { closest } from "@/javascript/components/tools/closest"
import Loader from "@/javascript/components/loader"

Loader.functional((content, resolve) => {
  content.querySelectorAll('a.js-one-click').forEach((elem) => {
    if(elem.preventMultiClickEnabled) {
      return
    }

    elem.preventMultiClickEnabled = true
    elem.addEventListener('click', (e) => {
      const targetElm = closest(e.target,'a.js-one-click')
      if(!targetElm) {
        return
      }
      if(targetElm.classList.contains('js-clicked')) {
        return e.preventDefault()
      }
      targetElm.classList.add('js-clicked')
    })
  })

  resolve()
})
